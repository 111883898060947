<template>
	<div class="card card-custom card-stretch gutter-b" ref="idirectGraphs">
		<div class="card-header border-0 align-items-center" v-if="remotes">
			<div>
				<h3 class="card-title font-weight-bolder text-dark">Active Remotes: {{ remotes.length }}</h3>
			</div>
		</div>
		<div class="card-body pt-0">
			<ITCSpinner :loaded="remotes.length > 0">
				<b-table :items="remotes" :fields="fields" hover tbody-tr-class="rowClass">
					<template #cell(NetModemName)="data">
						<router-link :to="{ name: 'site', params: { id: data.item.SiteId } }">{{ data.value }}</router-link>
					</template>
					<template #cell(status)="data">
						<div :class="['w-50', 'device-status-' + data.value, 'status']">{{ data.value }}</div>
					</template>
				</b-table>
			</ITCSpinner>
		</div>
	</div>
</template>

<script>
export default {
	name: 'NetworkRemotes',
	components: {
		ITCSpinner: () => import('@/view/content/loaders/itcSpinner.vue'),
	},
	props: ['remotes'],
	data() {
		return {
			fields: [
				{ key: 'NetModemName', label: 'Name', sortable: true, tdClass: 'w-50' },
				{ key: 'status', label: 'Status', sortable: true, tdClass: 'w-25' },
				{ key: 'model', label: 'Modem Type', sortable: true, tdClass: 'w-25' },
			],
		};
	},
};
</script>

<style scoped lang="scss">
::v-deep .rowClass td {
	vertical-align: middle;
}
.status {
	text-align: center;
	padding: 2px 5px;
	border-radius: 5px;
}
.device-status-OK {
	background: $success-light;
	color: $success;
}
.device-status-ALARM {
	background: #f7bbc0;
	color: $danger;
}
.device-status-DEACTIVATED {
	background: rgb(190, 185, 185);
	color: $dark-75;
}
.device-status-OFFLINE {
	background: #fef4de;
	color: $dark-75;
}
</style>
